import * as React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Button, Card } from "react-bootstrap"

import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import config from "../../siteconfig.esm"
import locales from "../locales/index.esm"

const labels = locales[process.env.GATSBY_LOCALE]

const IndexPage = props => {
  const { quizzes, cta, siteResources, siteData } = props.pageContext
  const generalResourceFirstCol = Math.ceil(siteResources.general.resources.length / 2)
  const siteSections = props.pageContext.siteData.sections.filter(s => s.field_section_path_name)

  function BreadCrumb() {
    if (config.setup.sections && !props.pageContext.main) {
      return <PageHeader crumbs={props.pageContext.breadcrumb} />
    } else {
      return <div className="breadcrumb-container"></div>
    }
  }

  function CtaSection() {
    // if !cta render empty element
    const imgsrc = cta.relationships?.field_cta_background_image
      ? cta.relationships.field_cta_background_image.localFile?.childImageSharp.gatsbyImageData
          .images.fallback.src
      : "/" + config.style.jumbotronImage || ""

    if (Object.keys(cta).length) {
      return (
        <div className={`jumbotron text-center ${config.style.jumbotronBase}`}>
          <Container>
            <h1 className="jumbotron-heading">{cta.field_cta_title}</h1>
            <span
              className="lead"
              dangerouslySetInnerHTML={{ __html: cta.field_cta_description?.processed || "" }}
            ></span>
            <p>
              {cta.field_cta_link?.uri ? (
                <Button
                  href={props.pageContext.main ? "#sections" : "#quizzes"}
                  className="btn-lg btn-primary my-2"
                >
                  {cta.field_cta_link?.title || ""}
                </Button>
              ) : (
                <></>
              )}
            </p>
          </Container>
          <img
            className="section-card"
            src={imgsrc}
            alt={cta.field_cta_background_image?.alt || ""}
            aria-hidden="true"
          />
        </div>
      )
    } else {
      return <></>
    }
  }

  function SectionsSection() {
    /*
     *  Note the first condition below checks if this is the main page for a site with multiple streams
     *  where each section has a related collection of quizzes
     *  for single-stream sites this will render an empty element
     */
    if (config.setup.sections && props.pageContext.main) {
      // main intro to quiz sections
      return (
        <div className="main cta" id="sections">
          <Container>
            <Row>
              {siteSections.map(s => (
                <Col md={Math.floor(12 / siteSections.length)} key={s.id}>
                  <Card
                    className={s.field_section_path_name}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Card.Body>
                      <Card.Title>{s.field_section_title}</Card.Title>
                      <Card.Subtitle>{s.field_section_subheader}</Card.Subtitle>
                      {s.field_section_description?.processed ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: s.field_section_description.processed,
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      <Button
                        href={`/${s.field_section_path_name}`}
                        className="btn-lg btn-primary my-2"
                      >
                        {s.relationships.field_section_cta[0].field_cta_link?.title || ""}
                      </Button>
                      <img
                        className="section-card"
                        src={
                          s.relationships.field_section_cta[0]?.relationships
                            ?.field_cta_background_image?.localFile.childImageSharp.gatsbyImageData
                            .images.fallback.src
                        }
                        alt={s.relationships.field_section_cta[0]?.field_cta_background_image.alt}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      )
    } else {
      return <></>
    }
  }

  function QuizSection() {
    if (!quizzes.length) return <></>
    return (
      <div className="album py-5 bg-white">
        <Container>
          <Row>
            <Col id="quizzes" name="quizzes" md="12" className="pt-1 pb-5 text-center">
              <h2>{labels["home.employment-quizzes"]}</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {quizzes.map(quiz => (
              <Col key={quiz.slug} lg="3" md="4" sm="6" className="mb-3 col-12">
                <Card className="text-center border-0">
                  <Link
                    to={`${siteData.basePath}quiz/${quiz.slug}`}
                    className="d-flex justify-content-center"
                    aria-hidden
                    tabIndex="-1"
                  >
                    <Card.Header
                      className="photo border-0 py-5 rounded-circle"
                      alt={quiz.field_quiz_image?.alt || ""}
                      style={{
                        backgroundImage: `url(${
                          quiz.relationships.field_quiz_image?.localFile?.childImageSharp
                            .gatsbyImageData.images.fallback.src || ""
                        })`,
                      }}
                    >
                      <span style={{ fontSize: "1px" }}></span>
                    </Card.Header>
                  </Link>
                  <Card.Body>
                    <h2 className="h5">{quiz.field_short_name}</h2>
                    <Link
                      to={`${siteData.basePath}quiz/${quiz.slug}`}
                      className="small text-uppercase"
                      aria-label={quiz.field_short_name + " quiz"}
                    >
                      {labels["take-this-quiz"]}
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    )
  }
  function ResourceSection() {
    if (!siteResources.specific.resources.length) return <></>
    return (
      <div className="album py-5 bg-light">
        <Container>
          <Row>
            <Col id="resources" name="resources" md="12" className="pt-1 pb-5 text-center">
              <h2>{siteResources.specific.title}</h2>
            </Col>
          </Row>
          <Row>
            {siteResources.specific.resources.map(cat => (
              <Col md="4" key={cat.category.id}>
                <div className="mb-4">
                  <Card.Body>
                    <h2 className="h5 mb-4">{cat.category.name}</h2>
                    <ul className="list-group card-text list-unstyled">
                      {cat.resources.map(res => (
                        <li className="list-group-item" key={res.field_resource_link.uri}>
                          <a href={res.field_resource_link.uri} target="_blank" rel="noreferrer">
                            {res.field_resource_link.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Card.Body>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    )
  }
  function GeneralResourcesSection() {
    if (!siteResources.general.resources.length) return <></>
    return (
      <div className="album py-5 bg-white">
        <Container>
          <Row className="d-flex justify-content-between">
            <Col md="4">
              <div className="d-flex flex-column">
                <div className="mb-4">
                  <Card.Body>
                    <h2>{siteResources.general.title}</h2>
                    <div className="d-flex justify-content-between align-items-center"></div>
                  </Card.Body>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="d-flex flex-column">
                <div className="mb-4">
                  {siteResources.general.resources.slice(0, generalResourceFirstCol).map(res => (
                    <Card.Body key={res.id}>
                      <h2 className="h5">{res.field_resource_title}</h2>
                      <Card.Text>{res.field_resource_description}</Card.Text>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <a
                            href={res.field_resource_link.uri}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={res.field_resource_title}
                          >
                            {res.field_resource_link.title}
                          </a>
                        </div>
                      </div>
                    </Card.Body>
                  ))}
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="d-flex flex-column">
                <div className="mb-4">
                  {siteResources.general.resources.slice(generalResourceFirstCol).map(res => (
                    <Card.Body key={res.id}>
                      <h2 className="h5">{res.field_resource_title}</h2>
                      <Card.Text>{res.field_resource_description}</Card.Text>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                          <a
                            href={res.field_resource_link.uri}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={res.field_resource_title}
                          >
                            {res.field_resource_link.title}
                          </a>
                        </div>
                      </div>
                    </Card.Body>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <Layout siteData={siteData} seo={{ title: siteData.pageTitles.home }}>
      <BreadCrumb />
      <CtaSection />
      <SectionsSection />
      <QuizSection />
      <ResourceSection />
      <GeneralResourcesSection />
    </Layout>
  )
}

export default IndexPage
